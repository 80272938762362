$(function()
{

	// toutes les requête de jquery se font avec le token csrf
	$.ajaxSetup({
		beforeSend: function(xhr, settings) {
			if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type)) {
				xhr.setRequestHeader('X-CSRF-TOKEN', window.vocab.csrf );
			}
		}
	});


	// Affichage / Masquage du menu
	$(document).on('click',".toggle-nav", function(evt)
	{
		evt.preventDefault();
		$("#main-nav").toggleClass('d-none');
	});


	// Affichage / Masquage recherche
	$(document).on('click',".toggle-search", function(evt)
	{
		evt.preventDefault();
		$("#main-search").toggleClass('d-none');
		$("#main-nav").addClass('d-none');
	});


	// Affichage / Masquage recherche sur la carte
	$(document).on('click',"#map-control .search", function(evt)
	{
		evt.preventDefault();
		$("#map-search").toggleClass('d-none');
		$(this).toggleClass('active');
	});


	// Fermeture contenu notice
	$(document).on('click',"#map-content-close", function(evt)
	{
		evt.preventDefault();
		$("#map-content-container").removeClass('active');
	});


	// Affichage / Masquage séquences carte
	$(document).on('click',"#toggle-song-list", function(evt)
	{
		evt.preventDefault();
		$("#song-list").toggleClass('d-none');
	});


	// Scroll vers un élément de la page
	$(document).on('click','a.scroll_to', function( evt )
	{
		evt.preventDefault();
		var target = $(this).attr('href');
	
		if ( $(this).data('offset') )
		{
			var offset = $(this).data('offset');
		}
		else
		{
			var offset = 0;			
		}
	
		$('html, body').animate(
		{
			scrollTop: $(target).offset().top + offset
		},250)
	
	});



	// clic sur un lien favoris [data-favorite] avec data-favorite="URL"
	$(document).on('click','a[data-favorite]', function( evt ) {

		evt.preventDefault();

		// Lancer la barre NProgress
		NProgress.start();

		// Récupérer l'URL du lien
		var url = $(this).attr('href');

		var link = this;

		var data = {
			value: $(this).data('favorite') ? 0 : 1
		};

		// charger le contenu de l'URL en POST; avec le token dans meta name=csrf
		$.post(url, data, function(json) {

			$(link).data('favorite',json.data.favorite);

			$(document).trigger('favorite',json.data);
			$(document).trigger('retoast');

		}).always( function() {
			NProgress.done();
		});

	});

	// recherche et met à jour tous les favoris de la page
	$(document).on('favorite',function( evt, data ) {
		
		var elt = $(".js-favorite-"+data.id);
		elt.data('favorite',data.favorite);

		var fa = elt.find('i')
		fa.removeClass('fal fad fas fa-light fa-duotone fa-solid');
		fa.addClass( data.favorite ? 'fad fa-duotone' : 'fas fa-solid' );
	});



	// affichage des message flash
	$(document).on( 'toast', function(evt) {
		console.log('toast');
	 	$("#toast-messages").find('.toast').each(function() {            
            bootstrap.Toast.getOrCreateInstance(this).show()
        })
	});

	// rechargement des messages flash et réaffichage
	$(document).on( 'retoast', function( evt ) {

		var url = window.vocab.url.toast
		$('#toast-messages').load( url, function( html ) {
			$(document).trigger('toast');
		});
	});
});